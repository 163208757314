import React from "react"
import "../../theme/info.less"
import Container from "../../info/components/Container"
import Seo from "../../components/seo"
import TermsOfUse from "../../info/components/TermsOfUse"

const TermOfUsePage = () => {
  return (
    <Container>
      <Seo title="Mentro: Learn with Mentors" />
      <TermsOfUse />
    </Container>
  )
}

export default TermOfUsePage
