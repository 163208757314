import React from "react"
import { Typography, Row, Col, Button } from "antd"
import "./TermsOfUse.less"

const { Title, Text } = Typography

export default function TermsOfUse() {
  return (
    <div className="termsOfUseParent">
      <div className="termsOfUseDiv">
        <Title className="title mainTitle">Terms of Use</Title>
        <Text className="text">
          When you join our programs, you're joining a community. And like any
          growing community, a few ground rules about expected behavior are good
          for everyone. These guidelines cover both online (e.g. app, website,
          mailing lists, social channels) and offline (e.g. in-person meetups)
          behavior.
        </Text>
        <Text className="text">
          Violations of this code of conduct can result in members being blocked
          or removed from the platform. Use your best judgement, and if you'd
          like more clarity or have questions feel free to reach out. <br />
          Be nice. We're all part of the same community, so be friendly,
          welcoming, and generally a nice person. Be someone that other people
          want to be around.
        </Text>
        <Text className="text">
          Be respectful and constructive. Remember to be respectful and
          constructive with your communication to fellow members. Don't get into
          flame wars, make personal attacks, vent, or rant unconstructively.
          Everyone should take responsibility for the community and take the
          initiative to diffuse tension and stop a negative thread as early as
          possible.
        </Text>
        <Text className="text">
          Be collaborative. Work together! We can learn a lot from each other.
          Share knowledge, and help each other out. Participate. Join in on
          discussions, show up for in-person meetings regularly, offer feedback,
          and help implement that feedback.
        </Text>
        <Text className="text">
          Step down considerately. If you have some form of responsibility in
          your community, be aware of your own constraints. If you know that a
          new job or personal situation will limit your time, find someone who
          can take over for you and transfer the relevant information (contacts,
          passwords, etc.) for a smooth transition.
        </Text>
        <Text className="text">
          Basic etiquette for online discussions. Don't send messages to a big
          list that only needs to go to one person. Keep off topic conversations
          to a minimum. Don't be spammy by advertising or promoting personal
          projects which are off topic.
        </Text>

        <Title className="title">Anti-Harassment Policy</Title>
        <Text className="text">
          All users, mentees, mentors and community members of Mentro events,
          including in-person and online attendees, event staff, speakers and
          Googlers, must abide by the following policy:
        </Text>
        <Text className="text">
          Be respectful to each other. Treat everyone with respect. Participate
          while acknowledging that everyone deserves to be here — and each of us
          has the right to enjoy our experience without fear of harassment,
          discrimination, or condescension, whether blatant or via
          micro-aggressions. All forms of communication should not demean
          others. Consider what you are saying and how it would feel if it were
          said to you or about you.
        </Text>
        <Text className="text">
          Speak up if you see or hear something. Harassment is not tolerated,
          and you are empowered to politely engage when you or others are
          disrespected. The person making you feel uncomfortable may not be
          aware of what they are doing, and politely bringing their behavior to
          their attention is encouraged.
        </Text>
        <Text className="text">
          We have a ZERO TOLERANCE POLICY for in-person or online harassment of
          any kind, including but not limited to:
        </Text>
        <ul>
          <li>Stalking/following</li>
          <li>Deliberate intimidation</li>
          <li>Harassing photography or recording</li>
          <li>Sustained disruption of talks or other events</li>
          <li>Offensive verbal language</li>
          <li>
            Verbal language that reinforces social structures of domination
          </li>
          <li>Sexual imagery and language in public spaces</li>
          <li>Inappropriate physical contact</li>
          <li>Unwelcome sexual or physical attention</li>
          <li>Physical or cyber threats in relation to, but not limited to:</li>
          <ul>
            <li>Neurodiversity</li>
            <li>Race</li>
            <li>Color</li>
            <li>National origin</li>
            <li>Gender identity</li>
            <li>Gender expression</li>
            <li>Sexual orientation</li>
            <li>Age</li>
            <li>Body size</li>
            <li>Disabilities</li>
            <li>Appearance</li>
            <li>Religion</li>
            <li>Pregnancy</li>
            <li>Military status</li>
            <li>Social demographic</li>
          </ul>
        </ul>
        <Text className="text">
          You are asked to stop any harassing behavior and are expected to
          comply immediately. Our zero tolerance policy means that we will look
          into and review every allegation of violation of our Event Community
          Guidelines and Anti-Harassment Policy and respond appropriately. To
          report any behavior that makes you or others feel uncomfortable, visit
          your event's website to file a report to your event's organizers.
        </Text>
        <Text className="text">
          This policy extends to talks, forums, workshops, codelabs, social
          media, all attendees, partners, sponsors, volunteers, staff, etc. You
          catch our drift. Metro reserves the right to refuse admittance to, or
          remove any person from, any Mentro hosted event (including future
          Mentro events) at any time in its sole discretion. This includes, but
          is not limited to, attendees behaving in a disorderly manner or
          failing to comply with this policy, and the terms and conditions
          herein, if a participant engages in harassing or uncomfortable
          behavior, the conference organizers may take any action they deem
          appropriate, including warning or expelling the offender from the
          conference with no refund or blocking the offender's account from
          participating online.
        </Text>
      </div>
    </div>
  )
}
